.my--routes--projects--index {
  .hello-customer {
    margin-bottom: 33px;
    font-size: 29px;
    text-align: center;
  }

  .this-is-an-overview-over-your-projects {
    margin-bottom: 29px;
    font-size: 22px;
    text-align: center;
  }

  .add-new-project-icon {
    display: flex;
    width: 92px;
    height: 92px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #2c9d8f;
    font-size: 48px;
  }

  .add-new-project-label {
    margin-top: 23px;
    font-size: 22px;
  }

  .add-new-project-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-radius: 5px;
    margin-top: 32px;
    background-color: #eaeaea;

    &:link,
    &:visited {
      text-decoration: none;

      .add-new-project-icon {
        color: #fff;
      }

      .add-new-project-label {
        color: #000;
      }
    }
  }

  .ended-projects {
    margin-top: 147px;
    margin-bottom: 35px;
    font-size: 22px;
    text-align: center;
  }
}
