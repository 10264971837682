.my--components--offers--card {
  position: relative;
  overflow: hidden;

  .offer-header {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .offer-card-attribute-header {
    font-size: 8px;
  }

  .offer-card-attribute-value {
    font-weight: bold;
    text-align: right;
  }

  .chosen-label {
    position: absolute;
    top: -30px;
    left: -90px;
    width: 200px;
    padding: 6px 0;
    background-color: #2c9d8f;
    color: #fff;
    font-size: 12px;
    text-align: center;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
  }
}
