[data-class*="components--customer--offers--modal"] {
  .offer-accept-button {
    margin-left: 18px;
    background-color: #2c9d8f;
  }

  .offer-reject-button {
    background-color: #9c1b1b;
  }

  .offer-accept-button,
  .offer-reject-button {
    width: 186px;
    padding: 11px;
    border-radius: 5px;
    font-size: 17px;
    text-align: center;

    &:link,
    &:visited {
      color: #fff;
      text-decoration: none;
    }
  }
}
