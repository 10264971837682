.routes--customer--project-parts--show {
  .attribute-header {
    margin-bottom: 8px;
    font-size: 9px;
  }

  .project-part-admin-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .project-part-work-areas {
    font-size: 18px;
    font-weight: bold;
  }

  .file-attachments-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .file-attachment-item {
    padding: 21px 24px;
    border-radius: 5px;
    margin-right: 22px;
    margin-bottom: 22px;
    background-color: #d7d7d7;
  }

  .new-file-attachment-button {
    display: flex;
    width: 143px;
    height: 131px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fa {
      margin-bottom: 8px;
      color: #bfbfbf;
      font-size: 34px;
    }
  }
}
