@use "stylesheets/variables" as *;

.my--components--layout {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: #fff;
  color: #000;

  a:link,
  a:visited {
    color: #000;
    text-decoration: none;
  }

  .customer-layout-header {
    @media (min-width: $md-from) {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .customer-layout-header-left {
    width: 300px;
    padding: 32px;
    text-align: center;

    @media (max-width: $sm-to) {
      display: none;
    }
  }

  .customer-layout-signed-in-as-user {
    font-size: 20px;
  }

  .customer-layout-you-can-sign-out-here {
    font-size: 12px;

    &:link,
    &:visited {
      color: #000;
      text-decoration: none;
    }
  }

  .customer-layout-header-middle {
    text-align: center;

    @media (min-width: $md-from) {
      padding: 20px;
    }
  }

  .customer-layout-logo-text {
    @media (max-width: $sm-to) {
      overflow: hidden;
      width: 100vw;
      padding: 20px 20px 0;
      font-size: 26px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (min-width: $md-from) {
      font-size: 34px;
    }
  }

  .customer-layout-safety-all-the-way {
    margin-top: 3px;

    @media (max-width: $sm-to) {
      margin-bottom: 20px;
    }
  }

  .customer-layout-header-right {
    padding: 32px;
    text-align: center;

    @media (max-width: $sm-to) {
      display: none;
    }
  }

  .customer-layout-need-help {
    font-size: 20px;
  }

  .customer-layout-you-can-always-call-us {
    font-size: 12px;
  }

  .customer-layout-children {
    width: 100%;
    max-width: 990px;
    flex-grow: 1;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;

    &[data-align-center="true"] {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
