@use "stylesheets/variables" as *;

.my--components--projects--card {
  padding: 35px;
  border-radius: 5px;
  background-color: #eaeaea;

  .project-name {
    margin-bottom: 11px;
    font-size: 18px;
    font-weight: bold;
  }

  .project-attributes {
    display: flex;
    margin-bottom: 75px;
  }

  .project-attribute {
    width: 200px;
  }

  .project-attribute-header {
    font-size: 11px;
  }

  .project-attribute-value {
    font-size: 14px;
    font-weight: bold;
  }

  .project-parts-headers {
    font-size: 14px;
    font-weight: bold;
  }

  .project-part-row {
    font-size: 14px;
  }
}
