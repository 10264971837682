.my--routes--new-project--step1 {
  .files-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 22px;
  }

  .file-container {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
