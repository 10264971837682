.my--components--layout--button {
  padding: 15px 22px;
  border: 0;
  border-radius: 5px;
  background-color: #2c9d8f;
  color: #fff;
  cursor: pointer;
  font-size: 16px;

  &:link,
  &:visited {
    color: #fff !important; // stylelint-disable-line declaration-no-important
  }
}
