.my--components--my-project--step-1--upload-file-button {
  .add-file-button {
    display: flex;
    width: 119px;
    height: 94px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #d7d7d7;
  }

  .add-file-circle-icon {
    display: flex;
    width: 32px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #bfbfbf;
  }

  .input-type-file {
    display: none;
  }
}
