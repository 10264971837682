.my--components--new-project--step-1--file-upload {
  .file-upload-image {
    display: flex;
    max-width: 92px;
    max-height: 84px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #d7d7d7;
  }
}
